import { Injectable } from "@angular/core";
import { VesselId } from "apina-frontend";
import { openWindow } from "../window/open-window";

@Injectable({providedIn: "root"})
export class VesselsService {

    openNewVesselDialog(): Promise<void> {
        return openWindow(`/vessels/new`, {popup: true, height: 850, width: 670, top: 0});
    }

    openEditVesselDialog(vesselId: VesselId): Promise<void> {
        return openWindow(`/vessels/${vesselId}/edit`, {popup: true, height: 850, width: 670, top: 0});
    }

    openVesselDialog(vesselId: VesselId): void {
        openWindow(`/vessels/${vesselId}`, {popup: true, height: 850, width: 670, top: 0});
    }
}
