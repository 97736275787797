<input #inputField matInput [formControl]="control" [placeholder]="placeholder" [matAutocomplete]="officeAutoComplete" (focus)="fieldReceivedFocus()">
<mat-autocomplete #officeAutoComplete="matAutocomplete" [autoActiveFirstOption]="true" (optionSelected)="selectOffice($event.option.value)">
    @if (hint(); as hint) {
        <mat-option disabled class="hint-option">{{hint}}</mat-option>
    }
    @if (!required && control.value === '' && value != null) {
        <mat-option [value]="null">-</mat-option>
    }
    @for (company of filteredOfficesByCompany(); track $index) {
        @if (company.showAsGroup) {
            <mat-optgroup [label]="company.name">
                @for (office of company.offices; track $index) {
                    <mat-option [value]="office">{{ office.name || company.name }}</mat-option>
                }
            </mat-optgroup>
        } @else if (company.offices.length === 1) {
            <mat-option [value]="company.offices[0]">
                {{ company.offices[0].companyName }}
            </mat-option>
        }
    }
</mat-autocomplete>
